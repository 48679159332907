.background {
  background-color: #f5f5f5;
  position: fixed;
  top: 0;


  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  }

.app {
  background: #e3fdfd;
  max-width: 960px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 500px;
  border-radius: 5px;
}
